<template>
  <v-select
    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
    :value="musteriSelect"
    :options="selectionMusteriList"
    class="w-100"
    :filter="filter"
    :reduce="val => isUyumEntityId ? val.entityId : val.value"
    @input="(val) => $emit('update:musteriSelect', val)" />
</template>

<script>
export default {
  props: {
    musteriSelect: {
      type: [Number, null],
      default: null,
    },
    isUyumEntityId: {
      type: [Boolean, null],
      default: false,
    },
  },
  computed: {
    selectionMusteriList() {
      return this.$store.getters['yonetimMusteri/getSelectionMusteris']
    },
  },
  created() {
    this.fetchSelectionMusteris();
  },
  methods: {
    fetchSelectionMusteris() {
      this.$store.dispatch('yonetimMusteri/fetchSelectionMusteris')
    },
    filter(options, search) {
      if (search) {
        return options.filter(x => x.label.toLocaleLowerCase().includes(search.toLocaleLowerCase()));
      }
      return options;
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
