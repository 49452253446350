<template>
  <v-select
    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
    :value="dbinfoSelect"
    :disabled="disable"
    :options="selectionDbFirmaInfoList"
    class="grup-filter-select"
    :reduce="val => val.value"
    @input="(val) => $emit('update:dbinfoSelect', val)" 
    :placeholder="$t('Veri Tabanı Seç')" />
</template>

<script>
export default {
  props: {
    disable: {
      type: Boolean,
      default: false,
    },
    dbinfoSelect: {
      type: [Number, null],
      default: null,
    },
  },
  computed: {
    selectionDbFirmaInfoList() {
      return this.$store.getters['dbInfoYonetim/getDbFirmaInfos'].map(item => {
        item.label = item.sDatabaseName;
        item.value = item.id;
        return item;
      })
    },
  },
  methods: {
    fetchDbFirmaInfos() {
      this.$store.dispatch('dbInfoYonetim/fetchDbFirmaInfos')
    },
  },
  created() {
    this.fetchDbFirmaInfos();
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
