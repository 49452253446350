import { ref, watch, computed, onMounted } from '@vue/composition-api'
import store from '@/store'

export default function useWebApiKullanicis() {
  const webApiKullanicisTable = ref(null)
  const resolveDatabasename = id => {
    const db = store.getters['dbInfoYonetim/getDbFirmaInfos'].find(x => x.id === id);
    if (db) {
      return db.sDatabaseName;
    }
    return '';
  }
  // Table Handlers
  const tableColumns = [
    { label: 'Adı', key: 'sAdi', sortable: true },
    { label: 'Kullanıcı Adı', key: 'sKullaniciAdi', sortable: true },
    { label: 'Parola', key: 'sParola', sortable: true },
    { label: 'Veri Tabanı', key: 'dbFirmaInfo_Id', sortable: true, sortByFormatted: true, formatter: value => resolveDatabasename(value) },
    { label: 'Aktif Durumu', key: 'status' },
    { label: 'Aksiyon', key: 'actions', class: 'text-right' },
  ]

  const perPage = ref(10)
  const toplamWebApiKullanici = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const sortBy = computed({
    get() {
      return store.getters['dbKullaniciYonetim/getWebApiKullaniciLocalFilters'].sortBy
    },
    set(value) {
      store.commit('dbKullaniciYonetim/setWebApiKullaniciFilter', { key: 'sortBy', value })
    },
  })
  const isSortDirDesc = computed({
    get() {
      return store.getters['dbKullaniciYonetim/getWebApiKullaniciLocalFilters'].sortDesc
    },
    set(value) {
      store.commit('dbKullaniciYonetim/setWebApiKullaniciFilter', { key: 'sortDesc', value })
    },
  })

  const dataMeta = computed(() => {
    const localItemsCount = webApiKullanicisTable.value ? webApiKullanicisTable.value.localItems.length : 0
    return {
      from: currentPage.value === 1 ? 1 : perPage.value * (currentPage.value - 1) + 1,
      to: perPage.value * (currentPage.value) < localItemsCount ? perPage.value * (currentPage.value) : localItemsCount,
      of: toplamWebApiKullanici.value,
    }
  })

  const refetchData = () => {
    webApiKullanicisTable.value.refresh()
  }

  watch([currentPage, perPage], () => {
    refetchData()
  })

  const fetchWebApiKullanicis = () => {
    const { sKullaniciAdi, dbFirmaInfo_Id, musteriId } = store.getters['dbKullaniciYonetim/getWebApiKullaniciLocalFilters'];
    store.dispatch('dbKullaniciYonetim/fetchWebApiKullanicis', { sKullaniciAdi, dbFirmaInfo_Id, musteriId })
  }

  const getWebApiKullanicis = computed(() => {
    const webApiKullanicis = store.getters['dbKullaniciYonetim/getWebApiKullanicis']
    toplamWebApiKullanici.value = webApiKullanicis.length
    return webApiKullanicis
  })

  onMounted(fetchWebApiKullanicis)

  return {
    fetchWebApiKullanicis,
    getWebApiKullanicis,
    tableColumns,
    perPage,
    currentPage,
    toplamWebApiKullanici,
    dataMeta,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    webApiKullanicisTable,
    }
}
